<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
      <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
      </div>
    </v-row><br><br><br />
    <v-card class="card">
      <v-card-title class="heading justify-center">Policy Type Report</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Academic year</label>
            <v-autocomplete v-model="selected_ay" placeholder="Select Academic Year" :items="aylist" item-text="ay"
              item-value="id" outlined dense class="text" required @change="getdivision()"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Program Type</label>
            <v-autocomplete v-model="selected_program_type" placeholder="Select Prgram Type" :items="porgram_type_list"
              item-text="name" item-value="id" outlined dense class="text" required
              @change="getprogram()"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Program</label>
            <v-autocomplete v-model="selected_program" placeholder="Select Prgram" :items="program_list" item-text="name"
              item-value="id" outlined dense class="text" required @change="getdivision()"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Year</label>
            <v-autocomplete v-model="selected_year" placeholder="Select Year" :items="year_list" item-text="year"
              item-value="id" outlined dense class="text" required @change="getdivision()"></v-autocomplete>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Semester</label>
            <v-autocomplete v-model="selected_sem" placeholder="Select Semester" :items="sem_list" item-text="sem"
              item-value="id" outlined dense class="text" required></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Shift</label>
            <v-autocomplete v-model="selected_shift" placeholder="Select shift" :items="shift_list" item-text="shift"
              item-value="id" outlined dense class="text" required></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Division</label>
            <v-autocomplete v-model="selected_division" placeholder="Select shift" :items="division_list" outlined dense
              class="text" required></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <label class="add-text">Policy Type</label>
            <v-autocomplete v-model="selected_policy_type" placeholder="Select Policy Type" :items="policy_type_list"
              item-text="name" item-value="id" outlined dense class="text" required></v-autocomplete>
          </v-col>
        </v-row>
        <div>
          <center>
            <v-btn color="success" class="mb-5" @click="get_policy_wise_data()">Fetch Data</v-btn>
          </center>
        </div>
      </v-card-text>
      <div v-if="overlay">
        <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-data-table :headers="headers" :search="search" :items="tabledata" class="elevation-5 ma-2">
          <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title>Policy Report</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
              <download-excel class="ma-4" :data="tabledata" :fields="export_table_headers"
                worksheet="Policty_type_report" name="policty_type_report.xls">
                <v-btn color="success" dark class="mb-2">
                  Export<v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>
            </v-toolbar>
          </template>
          <template v-slot:item.book="{ item }">
            <v-chip v-if="item.book > 0" class="green white--text" @click="get_book_info(item)">{{ item.book }}</v-chip>
            <v-chip v-else class="red white--text">{{ item.book }}</v-chip>
          </template>
        </v-data-table>
        <v-dialog v-model="book_dialog">
          <v-data-table :headers="book_header" :search="search1" :items="book_data" class="elevation-5 pa-5">
            <template v-slot:top>
              <v-toolbar flat color="white" align="center" justify="center">
                <v-toolbar-title>Book Data</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                {{ name }}
                <v-spacer></v-spacer>
                <v-text-field v-model="search1" append-icon="search" label="Search" single-line
                  hide-details></v-text-field>
                <download-excel class="ma-4" :data="book_data" :fields="export_book_table_headers"
                  worksheet="Policty_type_report_book" name="policty_type_report_book.xls">
                  <v-btn color="success" dark class="mb-2">
                    Export<v-icon dark>mdi-file-excel</v-icon>
                  </v-btn>
                </download-excel>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-dialog>
      </div>
    </v-card>

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { table2excel } from "../jquery.table2excel";
import VueBarcode from "vue-barcode";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    isrange: false,
    from: 1,
    to: 100,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Reports',
        disabled: false,
        href: '/reports',
      },
      {
        text: 'Policy Type Report',
        disabled: true,
        href: '',
      },
    ],
    fields: {
      Series: "series",
      "Accession No": "author",
      "Barcode No": "barcode_no",
      //"Barcode": "barcode",
      Barcode: {
        field: "barcode",
        callback: (item) => {
          return item == "" ? "" : `<barcode :value="item"></barcode>`;
        },
      },
    },
    overlay: false,
    accno: "",
    tabledata: [],
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Name",
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: "Program",
        align: "left",
        sortable: true,
        value: "program",
      },
      {
        text: "Year",
        align: "left",
        sortable: true,
        value: "year",
      },
      {
        text: "Division",
        align: "left",
        sortable: true,
        value: "division",
      },
      {
        text: "No of book",
        align: "left",
        sortable: true,
        value: "book",
      },
    ],
    book_header: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Accession No",
        align: "left",
        sortable: true,
        value: "accno",
      },
      {
        text: "Book Title",
        align: "left",
        sortable: true,
        value: "book_name",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "Books_authors",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Issue_date",
        align: "left",
        sortable: true,
        value: "issue_date",
      },
      {
        text: "return_date",
        align: "left",
        sortable: true,
        value: "return_date",
      },
    ],
    export_table_headers: {
      "Sr.no.": "srno",
      Name: "name",
      Program: "program",
      Year: "year",
      Division: "division",
      book: "book",
    },
    export_book_table_headers: {
      "Sr.no.": "srno",
      "Accession No": "accno",
      "Book Title": "book_name",
      Author: "Books_authors",
      Publisher: "publisher",
      "Issue Date": "issue_date",
      "Return Date": "return_date",
    },
    name: "",
    year_list: [],
    sem_list: [],
    policy_type_list: [],
    porgram_type_list: [],
    shift_list: [],
    aylist: [],
    division_list: [],
    program_list: [],
    book_dialog: false,
    book_data: [],
    search1: "",
    selected_ay: "",
    selected_program_type: "",
    selected_program: "",
    selected_year: "",
    selected_sem: "",
    selected_policy_type: "",
    selected_shift: "",
    selected_division: "",
  }),

  mounted() {
    this.init();
  },
  methods: {
    getyear() {
      var params = {
        programtypeid: this.selected_program_type,
      };
      axios
        .post("/Librarian/getyearfromprogramtype", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.year_list = res.data.year_list;
            this.year_list.push({ id: "ALL", year: "ALL" });
            this.selected_year = "ALL";
          } else {
            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);

          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    getdivision() {
      var params = {
        ay: this.selected_ay,
        program: this.selected_program,
        year: this.selected_year,
      };
      axios
        .post("/Librarian/getdivision", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.division_list = res.data.division_list;
            this.division_list.push("ALL");
          } else {
            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);

          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    get_book_info(value) {
      this.overlay = true;
      this.book_data = [];
      this.book_dialog = false;
      this.name = "";
      var params = {
        book_item: value.book_list,
      };
      axios
        .post("/Librarian/getbook_item_id_array", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.book_data = res.data.book_data;
            this.book_dialog = true;
            this.overlay = false;
            this.name = value.name;
          } else {
            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
            this.tabledata = [];
            this.overlay = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    get_policy_wise_data() {
      this.overlay = true;
      var params = {
        ay: this.selected_ay,
        program_type: this.selected_program_type,
        program: this.selected_program,
        sem: this.selected_sem,
        year: this.selected_year,
        shift: this.selected_shift,
        policy_type: this.selected_policy_type,
        division: this.selected_division,
      };
      axios
        .post("/Librarian/get_policy_wise_data", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.tabledata = res.data.student_data;
            console.log(this.tabledata);
          } else {
            this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
            this.tabledata = [];
            this.overlay = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.tabledata = [];
          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    init() {
      this.overlay = true;
      axios
        .post("/Librarian/getbook_bank_report_data")
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.aylist = res.data.aylist;
            this.selected_ay = res.data.current_ay.id;
            this.year_list = res.data.year_list;
            this.sem_list = res.data.sem_list;
            this.policy_type_list = res.data.policy_type_list;

            this.selected_division = "ALL";
            this.porgram_type_list = res.data.porgram_type_list;
            this.shift_list = res.data.shift_list;
            this.porgram_type_list.push({ id: "ALL", name: "ALL" });
            this.selected_program_type = "ALL";
            this.getprogram();
            this.sem_list.push({ id: "ALL", sem: "ALL" });
            this.selected_sem = "ALL";
            this.year_list.push({ id: "ALL", year: "ALL" });
            this.selected_year = "ALL";
            this.shift_list.push({ id: "ALL", shift: "ALL" });
            this.selected_shift = "ALL";
            this.selected_policy_type = this.policy_type_list[0].id;
            this.selected_program = "ALL";
            this.getdivision();
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", error); // show snackbar
          window.console.log(error);
          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    getprogram() {
      var params = { programtypeid: this.selected_program_type };
      axios
        .post("/Librarian/getprogram", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.program_list = res.data.programlist;
            this.program_list.push({ id: "ALL", name: "ALL" });
            this.selected_program = "ALL";
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
      this.getyear();
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.add-section {
  display: block;
  margin: 1rem;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}

.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}

.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}

.checkbox .box {
  margin-top: 5px;
}

.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>
